import React, {Fragment, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SEO from "../components/seo/seo";
import HomePageLayout from "../layouts/HomePageLayout";
import Breadcrumb from "../wrappers/breadcrumb/Breadcrumb";
import {Alert, Button, Input} from "reactstrap";
import AuthService from "../services/auth.service";
import {API_BASE_URL} from "../constants";
import PasswordValidationService from "../services/password-validation.service";
import {ListGroup} from "react-bootstrap";
import useNavigateUserService from "../services/navigate-user.service";
import UserRegistrationService from "../services/user-registration.service";

const LoginRegisterPage = () => {
    let {pathname} = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [userData, setUserData] = useState('');
    const [seconds, setSeconds] = useState(10);
    const [errorMsg, setErrorMsg] = useState([]);
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState('');
    const {handleGoHomePage, handleGoRequestNewRegistrationConfirmationEmailPage} = useNavigateUserService();

    const [rulesValidation, setRulesValidation] = useState({
        minChars: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        match: false
    });
    const [registrationFormErrors, setRegistrationFormErrors] = useState({});
    const [registrationFormPasswordErrors, setRegistrationFormPasswordErrors] = useState(false);
    const [registrationFormRepeatPasswordErrors, setRegistrationFormRepeatPasswordErrors] = useState(false);

    const handleSubmitLoginUser = async (event) => {
        event.preventDefault();
        try {
            const responseData = await login(email, password);
            if (responseData.success) {
                navigate("/", {state: {responseData}});
            }
        } catch (error) {
        }
    };

    const login = async (email, password) => {
        const hashedPassword = await AuthService.hashPassword(password);
        const loginUrl = `${API_BASE_URL}/api/login`;

        try {
            const requestData = {email, hashedPassword};
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            const responseData = await response.json();
            if (!responseData.success) {
                if (responseData.status === "401 Unauthorized") {
                    setErrorUnauthorizedMessage(UserRegistrationService.responseDictionary(responseData.message, email));
                } else {
                    setErrorMessage(UserRegistrationService.responseDictionary("11"))
                    throw new Error("Failed to login. Please try again later.");
                }
            }
            if (responseData.accessToken) {
                localStorage.setItem("user", JSON.stringify(responseData.user));
                localStorage.setItem("accessToken", JSON.stringify(responseData.accessToken));
                setUserData(JSON.stringify(responseData.user))
            }
            return responseData;
        } catch (error) {
            throw error;
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePassword(event.target.value, repeatPassword);
    };
    const handleChangeRepeatPassword = (event) => {
        setRepeatPassword(event.target.value);
        validatePassword(password, event.target.value);
    };
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleRepeatPasswordVisibility = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };
    const validatePassword = (password, repeatPassword) => {
        const validation = {
            minChars: password.length >= 10,
            upperCase: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            number: /\d/.test(password),
            match: password === repeatPassword
        };
        setRulesValidation(validation);
    };

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        magicCodeValue: ""
    });
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailure, setRegistrationFailure] = useState(false);
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };


    const handleSubmitRegisterUser = async (event) => {
        event.preventDefault();
        const hasFormErrors = Object.keys(registrationFormErrors).length > 0;

        if (!formData.firstName && formData.firstName.length < 2) {
            setRegistrationFormErrors((prevErrors) => ({...prevErrors, ["firstName"]: true}));
            setAlertMessage("First Name field cannot be empty or is too short. Please enter a valid first name. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        if (!formData.lastName && formData.lastName.length < 2) {
            setRegistrationFormErrors((prevErrors) => ({...prevErrors, ["lastName"]: true}));
            setAlertMessage("Last Name field cannot be empty or is too short. Please enter a valid last name. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        if (!formData.email && formData.email.length < 2) {
            setRegistrationFormErrors((prevErrors) => ({...prevErrors, ["email"]: true}));
            setAlertMessage("Email field cannot be empty or is too short. Please enter a valid email address. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        if (!password && password.length < 10) {
            setRegistrationFormPasswordErrors(true);
            setAlertMessage("Password field cannot be empty or is too short. Please enter a valid password. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        if (!repeatPassword && repeatPassword.length < 2) {
            setRegistrationFormRepeatPasswordErrors(true);
            setAlertMessage("Repeat Password field cannot be empty or is too short. Please enter a valid repeat password. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        if (!formData.magicCodeValue && formData.magicCodeValue.length < 1) {
            setRegistrationFormErrors((prevErrors) => ({...prevErrors, ["magicCodeValue"]: true}));
            setAlertMessage("Beta Access Code can't be empty. Please enter a valid Beta Access Code. ");
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
        const {isValid, error} = PasswordValidationService.validatePasswordForm(password, repeatPassword);
        if (!isValid) {
            setAlertMessage("Password and Password Confirm fields are not valid or matching. Please fill out the fields in the form correctly. ");
            setRegistrationFormPasswordErrors(true);
            setRegistrationFormRepeatPasswordErrors(true);
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }


        if (hasFormErrors) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            setAlertMessage("Please correct invalid entries.");
        } else {
            try {
                const hashedPassword = await AuthService.hashPassword(password);
                const registerUrl = `${API_BASE_URL}/api/user/register`;

                const response = await fetch(registerUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        userName: formData.userName,
                        email: formData.email,
                        hashedPassword: hashedPassword,
                        repeatHashedPassword: hashedPassword,
                        magicCodeValue: formData.magicCodeValue,
                    }),
                });
                let data = response.json();
                data.then(function (result) {
                    if (!result.success) {
                        setRegistrationFailure(true);
                        setRegistrationSuccess(false);
                        setFormSuccess("");
                        setFormError(`Registration ${result.message}`);
                        return;
                    }
                    setRegistrationSuccess(true);
                    setRegistrationFailure(false);
                    setFormError("");
                    setFormSuccess(`${result.message}`)
                    startCountdown();
                })
            } catch (error) {
                setFormError(`Registration ${error.message}`);
            }
        }
    };

    const startCountdown = () => {
        const countdown = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1200);

        return () => clearInterval(countdown);
    };

    useEffect(() => {
        if (seconds === 0) {
            window.location.href = '/login';
        }
    }, [seconds]);


    const handleBlur = (event) => {
        const {name, value} = event.target;
        const isValid = validateInput(name, value);

        if (!isValid) {
            setRegistrationFormErrors((prevErrors) => ({...prevErrors, [name]: true}));
            setAlertMessage(getValidationMessage(name));
        } else {
            setRegistrationFormErrors((prevErrors) => {
                const {[name]: removedError, ...rest} = prevErrors;
                return rest;
            });
            setAlertMessage('');
        }
    };
    const handleBlurPasswordFields = (event) => {
        const {name, value} = event.target;
        setRegistrationFormPasswordErrors(false)
        setRegistrationFormRepeatPasswordErrors(false)

        setAlertMessage('');
    };
    const handleForgotPassword = () => {
        navigate(process.env.PUBLIC_URL + "/forgot-password/request-reset-link");
    };

    const validateInput = (fieldName, value) => {
        if (value.length > 0) {
            switch (fieldName) {
                case 'firstName':
                case 'lastName':
                    return /^[a-zA-Z-]+$/.test(value);
                case 'userName':
                    return value.length > 2;
                case 'email':
                    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value);
                default:
                    return true;
            }
        } else {
            return true;
        }
    };
    const getValidationMessage = (fieldName) => {
        const validationErrorField = "Validation Error! Field => ";
        const enterOnlyLetters = "<= Please enter only letters.";
        const userNameMsg = "<= Entered username is too short. Please enter at least 3 characters.";
        const emailMsg = "<= Entered email is not correct. Please enter a valid email address.";
        switch (fieldName) {
            case 'firstName':
                return validationErrorField + "First Name " + enterOnlyLetters;
            case 'lastName':
                return validationErrorField + "Last Name " + enterOnlyLetters;
            case 'userName':
                return validationErrorField + "Username " + userNameMsg;
            case 'email':
                return validationErrorField + "Email " + emailMsg;
            default:
                return "Invalid input.";
        }
    };
    const getErrorInputFieldStyle = (fieldName) => {
        return {
            borderColor: registrationFormErrors[fieldName] ? '#ec4040' : '#D3D3D3',
            backgroundColor: registrationFormErrors[fieldName] ? '#F6E8E8FF' : '#fff',
            borderRadius: '0'
        }
    }
    const getErrorPasswordInputFieldStyle = () => {
        return {
            borderColor: registrationFormPasswordErrors || registrationFormRepeatPasswordErrors ? '#ec4040' : '#D3D3D3',
            backgroundColor: registrationFormPasswordErrors || registrationFormRepeatPasswordErrors ? '#F6E8E8FF' : '#fff',
            borderRadius: '0'
        }
    };
    const showAlertMessage = <>{alertMessage ?
        <Alert color="danger" isOpen={!!alertMessage} toggle={() => setAlertMessage('')}>
            {alertMessage}
        </Alert>
        : <div><br/><br/></div>
    }</>;

    const registrationMessageFailure = registrationFailure ?
        <div className="alert alert-danger request-status-message" role="alert">
            <p>{formError}</p>
        </div> : ''


    const eyeButtonStyle = {
        position: 'absolute',
        right: '15px',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    }

    return (
        <Fragment>
            <SEO
                titleTemplate="Login / Register"
                description="Login / Register user page of Their Last Place ancestors service."
            />
            <HomePageLayout
                headerContainerClass="container-fluid"
                headerBorderStyle="fluid-border"
                headerPaddingClass="header-padding-2"
            >
                <Breadcrumb
                    pages={[
                        {label: "Home", path: process.env.PUBLIC_URL + "/"},
                        {label: "Login Register", path: process.env.PUBLIC_URL + pathname}
                    ]}
                />
                <div className="login-register-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    <Tab.Container defaultActiveKey="login">
                                        <Nav fill variant="pills" className="login-register-tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="login">
                                                    <h4>Login</h4>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="register">
                                                    <h4>Register</h4>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>

                                            <Tab.Pane eventKey="login">
                                                <div className="login-form-container">
                                                    <div className="login-register-form">
                                                        <form onSubmit={handleSubmitLoginUser}>
                                                            <br/>
                                                            <br/>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">Please enter your login details:</div>
                                                            </div>
                                                            <hr/>
                                                            <br/>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <ListGroup.Item variant="light">Email: </ListGroup.Item>
                                                                    <Input
                                                                        type="text"
                                                                        id="email_login"
                                                                        name="email"
                                                                        value={email}
                                                                        onChange={handleEmailChange}
                                                                        style={getErrorInputFieldStyle('email_login')}
                                                                    />
                                                                    <ListGroup.Item variant="light">Password: </ListGroup.Item>
                                                                    <Input
                                                                        type="password"
                                                                        id="password_login"
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={handlePasswordChange}
                                                                        style={getErrorInputFieldStyle('password_login')}
                                                                    />
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                {errorUnauthorizedMessage && (
                                                                    <div className="alert alert-danger request-status-message" role="alert">
                                                                        <p>{errorUnauthorizedMessage}</p>
                                                                        <div className="login-register-form">
                                                                            <div className="row">
                                                                                <div className="button-box">
                                                                                    <div className="login-toggle-btn">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-12 col-md-12">
                                                                                                <Button onClick={handleGoRequestNewRegistrationConfirmationEmailPage} color="primary" className="w-100" style={{marginLeft: '0px'}}>
                                                                                                    Request a new registration confirmation email
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="row">
                                                                {errorMessage && (
                                                                    <div className="alert alert-danger request-status-message" role="alert">
                                                                        {errorMessage}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="row">
                                                                <div className="button-box">
                                                                    <div className="login-toggle-btn">
                                                                        {/*<input type="checkbox" />*/}
                                                                        {/*<label className="ml-10">Remember me</label>*/}

                                                                        <div className="row">
                                                                            <div className="col-lg-6 col-md-6">
                                                                                <Button type="submit" color="primary" className="w-100">Login</Button>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6">
                                                                                <Button onClick={handleForgotPassword} color="primary" className="w-100">
                                                                                    Forgot Password?
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="register">
                                                <div className="login-form-container">
                                                    <div className="login-register-form">
                                                        <form onSubmit={handleSubmitRegisterUser}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">
                                                                    {showAlertMessage}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">Please fill the form to register:</div>
                                                                <div className="col-md-4 mb-6 starred-label">
                                                                    <span>(*)</span> required fields
                                                                </div>
                                                            </div>

                                                            <hr/>
                                                            <br/>

                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-6">
                                                                        <ListGroup.Item variant="light" className="starred-label">First Name: <span>*</span></ListGroup.Item>
                                                                        <Input
                                                                            type="text"
                                                                            id="firstName"
                                                                            name="firstName"
                                                                            onBlur={handleBlur}
                                                                            value={formData.firstName}
                                                                            onChange={handleInputChange}
                                                                            style={getErrorInputFieldStyle('firstName')}

                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-6">
                                                                        <ListGroup.Item variant="light" className="starred-label">Last Name: <span>*</span></ListGroup.Item>
                                                                        <Input
                                                                            type="text"
                                                                            id="lastName"
                                                                            name="lastName"
                                                                            onBlur={handleBlur}
                                                                            value={formData.lastName}
                                                                            onChange={handleInputChange}
                                                                            style={getErrorInputFieldStyle('lastName')}

                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <ListGroup.Item variant="light" className="starred-label">Email: <span>*</span></ListGroup.Item>
                                                                        <Input
                                                                            type="email"
                                                                            id="email"
                                                                            name="email"
                                                                            onBlur={handleBlur}
                                                                            value={formData.email}
                                                                            onChange={handleInputChange}
                                                                            style={getErrorInputFieldStyle('email')}
                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <ListGroup.Item variant="light">Username:</ListGroup.Item>
                                                                        <Input
                                                                            type="text"
                                                                            id="userName"
                                                                            name="userName"
                                                                            onBlur={handleBlur}
                                                                            value={formData.userName}
                                                                            onChange={handleInputChange}
                                                                            style={getErrorInputFieldStyle('userName')}
                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <ListGroup.Item variant="light" className="starred-label">Password: <span>*</span>
                                                                            <button type="button" onClick={handleTogglePasswordVisibility} style={eyeButtonStyle}>
                                                                                <i className={showPassword ? "fa fa-eye fa-lg" : "fa fa-eye-slash fa-lg"}></i>
                                                                            </button>
                                                                        </ListGroup.Item>
                                                                        <Input type={showPassword ? 'text' : 'password'}
                                                                               id="password"
                                                                               onBlur={handleBlurPasswordFields}
                                                                               value={password}
                                                                               onChange={handlePasswordChange}
                                                                               style={getErrorPasswordInputFieldStyle()}
                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>

                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <ListGroup.Item variant="light" className="starred-label">Password Confirm: <span>*</span>
                                                                            <button type="button" onClick={handleToggleRepeatPasswordVisibility} style={eyeButtonStyle}>
                                                                                <i className={showRepeatPassword ? "fa fa-eye fa-lg" : "fa fa-eye-slash fa-lg"}></i>
                                                                            </button>
                                                                        </ListGroup.Item>
                                                                        <Input
                                                                            type={showRepeatPassword ? 'text' : 'password'}
                                                                            id="repeatPassword"
                                                                            onBlur={handleBlurPasswordFields}
                                                                            value={repeatPassword}
                                                                            onChange={handleChangeRepeatPassword}
                                                                            style={getErrorPasswordInputFieldStyle()}
                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <div className="change-password">
                                                                            <ListGroup.Item variant="dark">Password Rules:</ListGroup.Item>
                                                                            <div className="change-password-rules">
                                                                                <ListGroup.Item className={rulesValidation.minChars ? 'text-success' : 'text-danger'}>A minimum of 10 alphanumeric characters</ListGroup.Item>
                                                                                <ListGroup.Item className={rulesValidation.upperCase ? 'text-success' : 'text-danger'}>At least one uppercase letter</ListGroup.Item>
                                                                                <ListGroup.Item className={rulesValidation.lowerCase ? 'text-success' : 'text-danger'}>At least one lowercase letter</ListGroup.Item>
                                                                                <ListGroup.Item className={rulesValidation.number ? 'text-success' : 'text-danger'}>At least one number</ListGroup.Item>
                                                                                <ListGroup.Item className={rulesValidation.match ? 'text-success' : 'text-danger'}>New and confirm passwords match</ListGroup.Item>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroup>
                                                            </div>
                                                            <br/>
                                                            <div className="row">
                                                                <ListGroup>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <ListGroup.Item variant="light" className="starred-label">Beta Tests Access Code: <span>*</span></ListGroup.Item>
                                                                        <Input
                                                                            type="text"
                                                                            id="magicCodeValue"
                                                                            name="magicCodeValue"
                                                                            onBlur={handleBlur}
                                                                            value={formData.magicCodeValue}
                                                                            onChange={handleInputChange}
                                                                            style={getErrorInputFieldStyle('magicCodeValue')}
                                                                        />
                                                                    </div>
                                                                </ListGroup>
                                                            </div>

                                                            <br/>
                                                            <div className="row">
                                                                {registrationSuccess && (
                                                                    <div className="alert alert-success request-status-message" role="alert">
                                                                        <p>Registration successful!</p>
                                                                        <p>{formSuccess}</p>
                                                                        <p>Redirecting to the Home page in {seconds} seconds...</p>
                                                                        <div className="login-register-form">
                                                                            <div className="row">
                                                                                <div className="button-box">
                                                                                    <div className="login-toggle-btn">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-12 col-md-12">
                                                                                                <Button onClick={handleGoHomePage} color="primary" className="w-100" style={{marginLeft: '0px'}}>
                                                                                                    Home
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {registrationMessageFailure}
                                                            </div>
                                                            <div className="row">
                                                                <div className="button-box">
                                                                    <div className="login-toggle-btn">
                                                                        <div className="col-lg-12 col-md-12">
                                                                            <Button color="primary" type="submit" className="w-100">Register</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HomePageLayout>
        </Fragment>
    );
};

export default LoginRegisterPage;
